<template>
  <div>
    <div class="title">{{$t('baseInfo')}}</div>
    <el-form
        :rules="rules"
        ref="form"
        :model="form"
        :label-width="language === 'zh-cn' ? '200px' : '270px'"
    >
      <el-form-item :label="$t('supplierNameColumn')" prop="name">
        <el-input
            v-model="form.name"
            :placeholder="$t('formPlaceHolder.phSupplierName')"
            maxlength="60"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('registeredAddress')" prop="registeredAddress">
        <el-input
            v-model="form.registeredAddress"
            :placeholder="$t('formPlaceHolder.phRegisteredAddress')"
            maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('theRegisteredCapital')" prop="registeredCapital">
        <el-input
            v-model="form.registeredCapital"
            :placeholder="$t('formPlaceHolder.phRegisteredCapital')"
            style="width: calc(100% - 130px)"
            maxlength="50"
            @input="changeCapital"
        ></el-input>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item :label="$t('socialCreditCode')" prop="registeredNumber">
        <el-input
            v-model="form.registeredNumber"
            :placeholder="$t('formPlaceHolder.phUnifiedSocialCreditCode')"
            maxlength="18"
            minlength="18"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('businessScope')" prop="businessScope" style="width: 100%;">
        <el-input
            type="textarea"
            v-model="form.businessScope"
            :placeholder="$t('formPlaceHolder.phScopeOfBusiness')"
            :autosize="{ minRows: 4}"
            style="width: 90%;"
            maxlength="1000"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('businessAddress')" prop="officeAddress">
        <el-input
            v-model="form.officeAddress"
            :placeholder="$t('formPlaceHolder.phOfficeAddress')"
            maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('businessMail')" prop="corporateEmail">
        <el-input
            v-model="form.corporateEmail"
            :placeholder="$t('formPlaceHolder.phCorporateEmail')"
            maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item
          :label="$t('companyCreate.certificateOfIncorporation')"
          style="width: 100%;"
      >
        <file-upload
            :fileSize="4"
            :fileRequest="'/api/v1/upload/file'"
            :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
            @fileResponse="fileResponseCertificate"
            :isRequired="requiredCertificate"
            :isShowExampleImg="false"
        ></file-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileLicense') }}</div>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.businessRegistration')" required style="width: 100%;">
        <file-upload
            :fileSize="4"
            :fileRequest="'/api/v1/upload/file'"
            :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
            @fileResponse="fileResponseRegistration"
            :isRequired="requiredRegistration"
            :isShowExampleImg="false"
        ></file-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileLicense') }}</div>
      </el-form-item>
      <el-form-item
          :label="$t('companyCreate.legalHoldingStructure')"
          style="width: 100%;"
      >
        <file-upload
            :fileSize="4"
            :fileRequest="'/api/v1/upload/file'"
            :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
            @fileResponse="fileResponseStructure"
            :isRequired="requiredStructure"
            :isShowExampleImg="false"
        ></file-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileLicense') }}</div>
      </el-form-item>
      <el-form-item
          :label="$t('companyCreate.directorsDocumentation')"
          required
          style="width: 100%;"
      >
        <file-upload
            :fileSize="4"
            :fileRequest="'/api/v1/upload/file'"
            :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
            @fileResponse="fileResponseDocFront"
            :isRequired="requiredDocFront"
            :isShowExampleImg="false"
            :isShowUploadTips="true"
            showUploadTips="Front Side"
            class="upload-front"
        ></file-upload>
        <file-upload
            :fileSize="4"
            :fileRequest="'/api/v1/upload/file'"
            :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
            @fileResponse="fileResponseDocBack"
            :isRequired="requiredDocBack"
            :isShowExampleImg="false"
            :isShowUploadTips="true"
            showUploadTips="Back Side"
            class="upload-back"
        ></file-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileLicense') }}</div>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.directorName')" prop="directorName">
        <el-input
            v-model="form.directorName"
            :placeholder="$t('companyCreate.phDirectorName')"
            maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.directorIdNumber')" prop="directorCertNumber">
        <el-input
            v-model="form.directorCertNumber"
            :placeholder="$t('companyCreate.phDirectorIdNumber')"
            maxlength="18"
            minlength="18"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.contactPhoneNumber')" prop="directorMobileNumber">
        <el-input
            v-model="form.directorMobileNumber"
            :placeholder="$t('companyCreate.phContactPhoneNumber')"
            maxlength="20"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="title">{{$t('bankInformation')}}</div>
    <el-form
        ref="formBank"
        :model="formBank"
        :rules="rules"
        style="width: 1100px"
        label-width="200px"
    >
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" prop="areaId">
                <el-select v-model="bankCountryCity" :placeholder="$t('formPlaceHolder.phSelect')" style="width: 100%"
                           @change="changeCountry"
                >
                    <template v-if="language==='zh-cn'">
                        <el-option
                                v-for="item in optionsCountry"
                                :key="item.code"
                                :label="item.cnName"
                                :value="item.cnName">
                        </el-option>
                    </template>
                    <template v-else>
                        <el-option
                                v-for="item in optionsCountry"
                                :key="item.code"
                                :label="item.enName"
                                :value="item.enName">
                        </el-option>
                    </template>
                </el-select>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')" prop="receivingBank">
            <el-select v-if="optionsBank.length" v-model="bankName" :placeholder="$t('formPlaceHolder.phReceivingBankSel')" style="width: 100%"
                       @change="changeBank" ref="select"
            >
                <template v-if="language==='zh-cn'">
                    <el-option
                            v-for="item in optionsBank"
                            :key="item.swiftCode"
                            :label="item.cnName"
                            :value="item.cnName">
                        <div class="flex-row">
                            <el-image :src="item.icon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                            {{item.cnName}}
                        </div>
                    </el-option>
                </template>
                <template v-else>
                    <el-option
                            v-for="item in optionsBank"
                            :key="item.swiftCode"
                            :label="item.enName"
                            :value="item.enName">
                        <div class="flex-row">
                            <el-image :src="item.icon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                            {{item.enName}}
                        </div>
                    </el-option>
                </template>
            </el-select>
            <el-input v-else
                      v-model="formBank.receivingBank"
                      :placeholder="$t('formPlaceHolder.phReceivingBank')"
            ></el-input>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.swiftCode')" prop="swiftCode">
            <el-input
                    v-model="formBank.swiftCode"
                    :placeholder="$t('companyCreate.phSwiftCode')"
                    maxlength="30"
            ></el-input>
        </el-form-item>
      <el-form-item :label="$t('companyCreate.bankNumber')" prop="bankAccountNumber">
        <el-input
            v-model="formBank.bankAccountNumber"
            :placeholder="$t('companyCreate.phBankAccountNumber')"
            maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.bankAccount')" prop="accountHolderName">
        <el-input
            v-model="formBank.accountHolderName"
            :placeholder="$t('companyCreate.phAccountHolderName')"
            maxlength="50"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="title">{{$t('companyCreate.cooperationInfo')}}</div>
    <el-form
        ref="form3"
        :model="form3"
        :rules="rules"
        style="width: 100%"
        :label-width="language === 'zh-cn' ? '200px' : '300px'"
    >
      <el-form-item :label="$t('certificateOfCooperation')" style="width: 100%;">
        <el-upload
            class="avatar-uploader"
            :class="{'upload-required' : requiredCooperation && !form3.certificateIncorporation}"
            action="/api/v1/upload/file"
            accept="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG, .doc, .DOC, .docx, .DOCX, .pdf, .PDF'"
            :headers="{ token: this.$store.getters['auth/token'] }"
            :show-file-list="false"
            :on-success="fileResponseCooperation"
            :before-upload="beforeUpload"
            :isRequired="requiredCooperation"
        >
          <el-image
              v-if="/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(form3.certificateIncorporation)"
              :src="form3.certificateIncorporation"
              class="upload-img"
              fit="contain"
          ></el-image>
          <i
              v-if="/.*(\.doc|\.pdf|\.docx|\.DOC|\.PDF|\.DOCX)$/.test(form3.certificateIncorporation)"
              class="el-icon-files file-icon"
          ></i>
          <i v-if="!form3.certificateIncorporation" class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <!-- <file-upload
          :fileSize="20"
          :fileRequest="'/api/v1/upload/file'"
          :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG, .doc, .DOC, .docx, .DOCX, .pdf, .PDF'"
          @fileResponse="fileResponseCooperation"
          :isRequired="requiredCooperation"
          :isShowExampleImg="false"
          class="upload-front"
        ></file-upload>-->
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileCooperation') }}</div>
      </el-form-item>
      <el-form-item :label="$t('businessLogoUpload')" required style="width: 100%;">
        <file-upload
            :fileSize="2"
            :fileRequest="'/api/v1/upload/file'"
            :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
            :fileWidth="360"
            :fileHeight="220"
            @fileResponse="fileResponseLogo"
            :isRequired="requiredLogo"
            :isShowExampleImg="false"
        ></file-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileLogo') }}</div>
      </el-form-item>
    </el-form>
    <div class="flex-row-center">
      <el-button class="btn-black" @click="submitClick()">{{$t('submit')}}</el-button>
      <el-button class="btn-gray">{{$t('btnI18n.Cancel')}}</el-button>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/views/components/FileUpload";
import {getObjByKeyValue} from "@/utils";
// import FileUploadIdCard from "@/views/components/FileUploadIdCard";
export default {
  name: "company-create",
  components: {
    FileUpload
    // FileUploadIdCard
  },
  data() {
    let regEmail = (rule, value, callback) => {
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ig;
      if (value === '' || value === null) {
        callback(new Error(this.$t('completeInput')));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('formValidate.emailError')));
      }
    };
    return {
      imgUrl: this.$enums.IMG_URL,
      requiredCooper: false,
      requiredCertificate: false,
      requiredRegistration: false,
      requiredStructure: false,
      requiredDocFront: false,
      requiredDocBack: false,
      requiredCooperation: false,
      requiredLogo: false,
      form: {
        directorName: "",
        directorCertNumber: "",
        registeredNumber: "",
        name: "",
        registeredAddress: "",
        registeredCapital: "",
        businessScope: "",
        officeAddress: "",
        corporateEmail: "",
        coi: '',
        businessRegistration: '',
        legalHoldingStrcture: '',
        directorCertFront: '',
        directorCertBack: '',
        directorMobileNumber: ""
      },
        // bank update
        optionsCountry: [],
        optionsBank: [],
        bankCountryCity: "",
        bankName: "",
        // bank update
      formBank: {
          areaId: "",
          areaBankId: "",
          receivingBank: "",
          receivingBankEn: "",
          swiftCode: "",
          receivingBankIcon: "",
          bankAccountNumber: "",
          accountHolderName: "",
      },
      form3: {
        certificateIncorporation: "",
        companyLogo: "",
      },
      rules: {
        name: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        officeAddress: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        // registeredCapital: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        registeredAddress: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        directorName: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        directorCertNumber: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        directorMobileNumber: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        corporateEmail: [{ validator: regEmail, required: true, trigger: 'blur' }],
        registeredNumber: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        businessScope: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        accountHolderName: [{ required: true, message: this.$t('companyCreate.userCannotEmpty'), trigger: 'blur' }],
        bankAccountNumber: [{ required: true, message: this.$t('companyCreate.accountCannotEmpty'), trigger: 'blur' }],
          areaId: [{ required: true, message: this.$t('companyCreate.accountCannotEmpty'), trigger: 'blur' }],
          receivingBank: [
              { required: true, message: this.$t('companyCreate.bankCannotEmpty'), trigger: 'blur' },
              { required: true, message: this.$t('companyCreate.bankCannotEmpty'), trigger: 'change' }
          ],
        swiftCode: [{ required: true, message: this.$t('companyCreate.swiftCodeCannotEmpty'), trigger: 'blur' },
            { required: true, message: this.$t('companyCreate.swiftCodeCannotEmpty'), trigger: 'change' }]
      },
      fileList: [],
      headers: {}
    };
  },
    mounted() {
        this.headers = { address: this.$store.getters['auth/user'].authorization, type: this.$store.getters['auth/user'].type };
        this.getAreaList();
    },
  methods: {
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/v1/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getBankList(code) {
          let _this = this;
          this.optionsBank = [];
          this.$axios.get("/v1/bank/list", {params: { areaCode: code.toString() || "86" }}).then(res => {
              if (!res.code) {
                  _this.optionsBank = res.data;
              }
          });
      },
      // bank update
    changeCapital(val) {
      let valIndex = val.indexOf('.');
      if (valIndex !== -1) {
        val = this.form.registeredCapital = val.substring(0, valIndex + 3);
      }
    },
    fileResponseCertificate(response) {
      if (response.data) {
        this.form.coi = response.data;
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.warning(this.$t("tipsMessage.UploadImgError"));
      }
      return isLt20M;
    },
    fileResponseRegistration(response) {
      if (response.data) {
        this.form.businessRegistration = response.data;
      }
    },
    fileResponseStructure(response) {
      if (response.data) {
        this.form.legalHoldingStrcture = response.data;
      }
    },
    fileResponseDocFront(response) {
      if (response.data) {
        this.form.directorCertFront = response.data;
      }
    },
    fileResponseDocBack(response) {
      if (response.data) {
        this.form.directorCertBack = response.data;
      }
    },
    fileResponseCooperation(response) {
      if (response.data) {
        this.form3.certificateIncorporation = response.data;
      }
    },
    fileResponseLogo(response) {
      if (response.data) {
        this.form3.companyLogo = response.data;
      }
    },
      // bank update
      changeCountry(e) {
          this.bankName = "";
          this.formBank.receivingBank = "";
          this.formBank.receivingBankEn = "";
          this.formBank.swiftCode = "";
          this.formBank.receivingBankIcon = "";
          const key = this.language === "zh-cn" ? "cnName" : "enName";
          const {code} = getObjByKeyValue(this.optionsCountry, key, e);
          this.formBank.areaId = code.toString();
          this.getBankList(code);
      },
      changeBank(e) {
          const key = this.language === "zh-cn" ? "cnName" : "enName";
          const {swiftCode, icon, cnName, enName, id} = getObjByKeyValue(this.optionsBank, key, e);
          this.formBank.areaBankId = id;
          this.formBank.receivingBank = cnName;
          this.formBank.receivingBankEn = enName;
          this.formBank.swiftCode = swiftCode;
          this.formBank.receivingBankIcon = icon;
          this.$refs.select.$el.children[0].children[0].setAttribute('style',"background:url("+ icon +") no-repeat 10px;background-size: 20px 20px;color:#333;padding-left: 45px;");
      },
      // bank update
    submitClick() {
      let submit = true;
      if (!this.form.businessRegistration || !this.form.directorCertFront || !this.form.directorCertBack || !this.form3.companyLogo) {
        submit = false;
        // this.requiredCertificate = !this.form.coi;
        this.requiredRegistration = !this.form.businessRegistration;
        // this.requiredStructure = !this.form.legalHoldingStrcture;
        this.requiredDocFront = !this.form.directorCertFront;
        this.requiredDocBack = !this.form.directorCertBack;
        // this.requiredCooperation = !this.form3.certificateIncorporation;
        this.requiredLogo = !this.form3.companyLogo;
        return this.$message.error(this.$t('plaseUpload'));
      }
      submit && this.$refs.form.validate((valid) => {
        if (!valid) {
          submit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      submit && this.$refs.formBank.validate((valid) => {
        if (!valid) {
          submit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      submit && this.$refs.form3.validate((valid) => {
        if (!valid) {
          submit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      if (submit) {
        let info = Object.assign({}, this.form, this.formBank, this.form3);
        info.registeredCapital = ((info.registeredCapital) * this.$enums.UNIT_MILLION).toString();
        this.$axios.post('/v1/supplier/detail', info).then(result => {
          if (result.code === 0 || result.code === '0') {
            this.$message({ type: 'success', message: this.$t('submittedSuccessfully') });
            this.$router.push({ path: '/review' });
          } else {
            this.$message({ type: 'error', message: result.message });
          }
        });
      }
    }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 550px;
  display: inline-block;
}
.check-example {
  margin-left: 15px;
}
.upload-front,
.upload-back {
  display: inline-block;
}
.upload-back {
  margin-left: 20px;
}
::v-deep .avatar-uploader .el-upload {
  width: 148px;
  height: 148px;
  border: 1px dashed #c0ccda;
  background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .upload-img {
    width: 100%;
    height: 100%;
  }
  .file-icon {
    font-size: 60px;
  }
}
</style>
